import React, { useEffect, useRef, useState } from 'react'
import { Tabs } from 'antd'
import { useParams, useNavigate } from 'react-router-dom'
import { getBrandHouseListAPI, getAllBrandListAPI } from '../../../apis/house'
import './brandMap.module.scss'

let houseList = []
export default function BrandMap() {
  let map = null
  const TMap = window.TMap

  const location = useParams()
  const [id, setId] = useState(Number(location.id))
  console.log('%c [ id ]-13', 'font-size:13px; background:pink; color:#bf2c9f;', id)
  const mapDom = useRef(null)
  console.log('%c [ mapDom ]-14', 'font-size:13px; background:pink; color:#bf2c9f;', mapDom)
  const navigate = useNavigate()

  const [brandList, setBrandList] = useState([]) // 品牌列表
  //const [houseList, setHouseList] = useState([]) // 楼盘列表

  //  获取全部品牌列表
  const getAllBrandList = async () => {
    const res = await getAllBrandListAPI()
    if (res.success === true) {
      if (res.result) {
        setBrandList(res.result)
      }
    }
  }

  //  获取楼盘列表
  const getHouseList = async () => {
    houseList.forEach(item => {
      item.close()
    })
    houseList = []
    const res = await getBrandHouseListAPI({ id })
    if (res.success === true) {
      if (res.result) {
        let markersCla = 'style="background: #ffffff; padding: 10px; box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.12); border-radius: 4px; position: relative;"'
        let houseInfo = 'style="z-index: 99; position: relative;"'
        let houseName = 'style="font-size: 14px; font-weight: 600;"'
        let price = 'style="font-size: 12px; color:#666666; margin-top: 4px;"'
        let triangle = 'style="position: absolute; left: 50%; bottom: 0; transform: translateX(-50%) rotate(45deg); background: #ffffff; width: 20px; height: 20px; "'

        res.result.forEach(item => {
          let marker = new TMap.InfoWindow({
            map,
            position: new TMap.LatLng(item.latitude, item.longitude),
            enableCustom: true,
            content: '<div ' + markersCla + '><div ' + houseInfo + '><div ' + houseName + '>' + item.houseName + '</div><div ' + price + '>参考价：<span style="color: #FA5151;">' + item.price + '元/㎡</span></div></div><div ' + triangle + '></div></div>'
          })
          houseList.push(marker)
        })
      }
    }
  }
  //  tab切换
  const changeTabs = key => {
    let path = '/managecenter/brandmap/' + key
    navigate(path)
    setId(key)
    map && map.destroy()
  }

  useEffect(() => {
    if (brandList.length === 0) {
      getAllBrandList()
    }

    //  定义地图中心点坐标
    var center = new TMap.LatLng(30.606531, 114.31618)

    console.log('%c [ map ]-75', 'font-size:13px; background:pink; color:#bf2c9f;', map)
    map = new TMap.Map(mapDom.current, {
      center: center,
      zoom: 12,
      viewMode: '2D'
    })
    getHouseList()
  }, [id])

  return (
    <div className="h-full ">
      <Tabs
        activeKey={id}
        style={{
          height: 58,
          paddingTop: 0
        }}
        items={brandList.map(item => {
          return {
            label: item.name,
            key: item.id
          }
        })}
        onTabClick={changeTabs}
      />
      <div ref={mapDom} style={{ width: '100%', height: '100%' }} />
    </div>
  )
}
